import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Form, Button, Row, Col } from "react-bootstrap";

const MonHocManagement = () => {
  const [monHocs, setMonHocs] = useState([]);
  const [phongBans, setPhongBans] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newMonHoc, setNewMonHoc] = useState({
    MaMonHoc: "",
    TenMonHoc: "",
    Video: "",
    ArrayPB: [],
    ArrayRole: [],
  });
  const [errors, setErrors] = useState({
    ArrayPB: false,
    ArrayRole: false,
  });

  // let [updateLink, setUpdateLink] = useState(true);

  useEffect(() => {
    fetchMonHocs();
    fetchPhongBans();
    fetchRoles();
  }, []);

  const fetchMonHocs = async () => {
    try {
      const response = await axios.get("https://server.congtien.com.vn/monhoc");
      setMonHocs(response.data);
    } catch (error) {
      console.error("Error fetching MonHocs:", error);
    }
  };

  const fetchPhongBans = async () => {
    try {
      const response = await axios.get("https://server.congtien.com.vn/phongbans");
      setPhongBans(response.data);
    } catch (error) {
      console.error("Error fetching PhongBans:", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get("https://server.congtien.com.vn/roles");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching Roles:", error);
    }
  };

  const handleChange = (e) => {
    setNewMonHoc({ ...newMonHoc, [e.target.name]: e.target.value });
  };

  const handleArrayChange = (e, arrayType) => {
    const { value, checked } = e.target;
    const updatedArray = checked
      ? [...newMonHoc[arrayType], value]
      : newMonHoc[arrayType].filter((item) => item !== value);
    setNewMonHoc({ ...newMonHoc, [arrayType]: updatedArray });
  };

  // let updateLink = false;

  const handleChangeLink = async (_id, _state) => {
    if (!_state) {
      const monHocUpdateData = {
        LinkMonHoc: "/ctdt/questionscomponent",
        trangthai: 1,
      }; // Update as needed
      await axios.put(
        `https://server.congtien.com.vn/monhoc/${_id}`,
        monHocUpdateData
      );

      //----------------------------
      // const button = document.querySelector(`#btn_${_id}`);
      // button.removeEventListener("click", handleChangeLink);
      // button.innerText = "Hiện";
      // button.classList.remove("btn-dark");
      // button.classList.add("btn-success");
      // button.onclick = () => handleChangeLink(_id, true);

      //------------------------

      //
      alert("Đã hiện");
    } else {
      const monHocUpdateData = {
        LinkMonHoc: "/underdevelopment",
        trangthai: 0,
      }; // Update as needed
      await axios.put(
        `https://server.congtien.com.vn/monhoc/${_id}`,
        monHocUpdateData
      );
      // const button = document.querySelector(`#btn_${_id}`);
      // button.removeEventListener("click", handleChangeLink);
      // button.innerText = "Ẩn";
      // button.classList.remove("btn-success");
      // button.classList.add("btn-dark");
      // button.onclick = () => handleChangeLink(_id, false);
      alert("Đã Ẩn");
    }
    window.location.reload();
  };

  // const handleChangeLinkHide = async (_id) => {  updateLink = false;
  // setUpdateLink(false);

  // if (!updateLink && _id) {
  //   const monHocUpdateData = { LinkMonHoc: "/Underdevelopent", trangthai: 0 }; // Update as needed
  //   await axios.put(`https://server.congtien.com.vn/monhoc/${_id}`, monHocUpdateData);
  //   alert("Đã Ẩn");
  // }};

  const handleCreateMonHoc = async (e) => {
    e.preventDefault();

    // Kiểm tra nếu ít nhất một checkbox được chọn trong mỗi nhóm
    const isArrayPBSelected = newMonHoc.ArrayPB.length > 0;
    const isArrayRoleSelected = newMonHoc.ArrayRole.length > 0;

    if (!isArrayPBSelected || !isArrayRoleSelected) {
      setErrors({
        ArrayPB: !isArrayPBSelected,
        ArrayRole: !isArrayRoleSelected,
      });
      alert("Vui lòng chọn ít nhất một phòng ban và vai trò.");
      return;
    }

    // Chuyển đổi định dạng ArrayPB và ArrayRole
    const formattedMonHoc = {
      ...newMonHoc,
      ArrayPB: newMonHoc.ArrayPB.map((pb) => ({ _idPhongBan: pb })),
      ArrayRole: newMonHoc.ArrayRole.map((role) => ({ _idRole: role })),
    };

    try {
      const response = await axios.post(
        "https://server.congtien.com.vn/monhoc",
        formattedMonHoc
      );
      setMonHocs([...monHocs, response.data]);
      alert("Thêm môn học mới thành công");
      setNewMonHoc({
        MaMonHoc: "",
        TenMonHoc: "",

        Video: "",
        ArrayPB: [],
        ArrayRole: [],
      });
      setErrors({
        ArrayPB: false,
        ArrayRole: false,
      });
    } catch (error) {
      console.error("Error creating MonHoc:", error);
    }
  };

  const handleDeleteMonHoc = async (_id) => {
    if (window.confirm("Bạn có chắc chắn muốn xóa môn học này không?")) {
      try {
        const response = await axios.get(
          `https://server.congtien.com.vn/check-monhoc/${_id}`
        );
        if (response.data.isUsed) {
          alert("Không thể xóa môn học này vì đang được sử dụng.");
          return;
        }

        await axios.delete(`https://server.congtien.com.vn/monhoc/${_id}`);
        setMonHocs(monHocs.filter((monHoc) => monHoc._id !== _id));
        alert("Xóa môn học thành công.");
      } catch (error) {
        console.error("Error deleting MonHoc:", error);
      }
    }
  };

  return (
    <div className="mt-4">
      <h2>Quản lý Môn Học</h2>
      <Form onSubmit={handleCreateMonHoc} className="mb-4 _form">
        <Form.Group className="_label" controlId="MaMonHoc">
          <Form.Label>Mã Môn Học</Form.Label>
          <Form.Control
            type="text"
            name="MaMonHoc"
            value={newMonHoc.MaMonHoc}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="_label mt-2" controlId="TenMonHoc">
          <Form.Label>Tên Môn Học</Form.Label>
          <Form.Control
            type="text"
            name="TenMonHoc"
            value={newMonHoc.TenMonHoc}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="_label mt-2" controlId="Video">
          <Form.Label>Video</Form.Label>
          <Form.Control
            type="text"
            name="Video"
            value={newMonHoc.Video}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Phòng Ban */}
        <Form.Group className="_label mt-2" controlId="ArrayPB">
          <Form.Label>Chọn Phòng Ban</Form.Label>
          <Row className="_row ">
            {phongBans.map((pb, index) => (
              <Col className="mt-2 " sm={4} md={3} key={pb._id}>
                <Form.Check
                  className="text-capitalize fw-normal"
                  type="checkbox"
                  label={pb.TenPhongBan.toLowerCase()}
                  id={`phongBan-${index}`}
                  name="ArrayPB"
                  value={pb._id}
                  checked={newMonHoc.ArrayPB.includes(pb._id)}
                  onChange={(e) => handleArrayChange(e, "ArrayPB")}
                />
              </Col>
            ))}
          </Row>
          {errors.ArrayPB && (
            <p style={{ color: "red" }}>Vui lòng chọn ít nhất một phòng ban.</p>
          )}
        </Form.Group>

        {/* Vai Trò */}
        <Form.Group className="_label mt-2" controlId="ArrayRole">
          <Form.Label>Chọn Vai Trò</Form.Label>
          <Row className="_row ">
            {roles.map((role, index) => (
              <Col className="mt-2 fw-normal" sm={4} md={3} key={role._id}>
                <Form.Check
                  className="text-capitalize"
                  type="checkbox"
                  label={role.TenRole.toLowerCase()}
                  id={`role-${index}`}
                  name="ArrayRole"
                  value={role._id}
                  checked={newMonHoc.ArrayRole.includes(role._id)}
                  onChange={(e) => handleArrayChange(e, "ArrayRole")}
                />
              </Col>
            ))}
          </Row>
          {errors.ArrayRole && (
            <p style={{ color: "red" }}>Vui lòng chọn ít nhất một vai trò.</p>
          )}
        </Form.Group>

        <div>
          <Button variant="primary" type="submit" className="mt-3 mb-3">
            Thêm Môn Học
          </Button>
        </div>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Mã Môn Học</th>
            <th>Tên Môn Học</th>
            <th className="text-center">Ẩn/Hiện</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {monHocs.map((monHoc, index) => (
            <tr key={monHoc._id}>
              <td>{index + 1}</td>
              <td>{monHoc.MaMonHoc}</td>
              <td>{monHoc.TenMonHoc}</td>
              <td className="text-center">
                {monHoc.trangthai === 1 && (
                  <Button
                    variant="dark"
                    id={`btn_${monHoc._id}`}
                    onClick={() => handleChangeLink(monHoc._id, true)}
                  >
                    Ẩn
                  </Button>
                )}
                {monHoc.trangthai === 0 && (
                  <Button
                    variant="success"
                    id={`btn_${monHoc._id}`}
                    onClick={() => handleChangeLink(monHoc._id, false)}
                  >
                    Hiện
                  </Button>
                )}
              </td>
              <td className="text-center">
                <Button
                  variant="danger"
                  onClick={() => handleDeleteMonHoc(monHoc._id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MonHocManagement;
