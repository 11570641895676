import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert, Button, Container, Form, Row, Col } from "react-bootstrap";

const CauHoiManagement = () => {
  const [form, setForm] = useState({
    NDCauHoi: "",
    A: "",
    B: "",
    C: "",
    D: "",
    E: "",
    DapAnDung: "",
    _idMonHoc: "",
  });
  const [monHocs, setMonHocs] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const fetchMonHocs = async () => {
      try {
        const response = await axios.get(
          "https://server.congtien.com.vn/monhoc"
        );
        setMonHocs(response.data);
      } catch (err) {
        console.error("Đã xảy ra lỗi khi lấy danh sách môn học", err);
      }
    };

    fetchMonHocs();
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    try {
      await axios.post("https://server.congtien.com.vn/cauhoi", form);
      setSuccess("Câu hỏi đã được thêm thành công");
      setForm({
        NDCauHoi: "",
        A: "",
        B: "",
        C: "",
        D: "",
        E: "",
        DapAnDung: "",
        _idMonHoc: "",
      });
    } catch (err) {
      setError("Đã xảy ra lỗi khi thêm câu hỏi");
    }
  };

  return (
    <Container className="container mt-3">
      <h1>Thêm câu hỏi mới</h1>
      <Form className="_form" onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mt-4 _label">
          <Form.Label column sm={2} htmlFor="NDCauHoi">
            Nội dung câu hỏi
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="NDCauHoi"
              name="NDCauHoi"
              value={form.NDCauHoi}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="A">
            A
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="A"
              name="A"
              value={form.A}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="B">
            B
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="B"
              name="B"
              value={form.B}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="C">
            C
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="C"
              name="C"
              value={form.C}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="D">
            D
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="D"
              name="D"
              value={form.D}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>

        {/* Uncomment if you need the E field */}
        {/* <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="E">E</Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="E"
              name="E"
              value={form.E}
              onChange={handleChange}
            />
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} className="mt-3 _label">
          <Form.Label column sm={2} htmlFor="DapAnDung">
            Đáp án đúng
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              id="DapAnDung"
              name="DapAnDung"
              value={form.DapAnDung}
              onChange={handleChange}
              required
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mt-3 mb-3 _label">
          <Form.Label column sm={2} htmlFor="_idMonHoc">
            Mã môn học
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              id="_idMonHoc"
              name="_idMonHoc"
              value={form._idMonHoc}
              onChange={handleChange}
              required
            >
              <option value="">Chọn môn học</option>
              {monHocs.map((monHoc) => (
                <option key={monHoc._id} value={monHoc._id}>
                  {monHoc.TenMonHoc}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        <Button type="submit" className="btn btn-primary">
          Thêm câu hỏi
        </Button>
      </Form>
    </Container>
  );
};

export default CauHoiManagement;
