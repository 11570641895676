import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Container, Form } from "react-bootstrap";

const Setting = () => {
  const [form, setForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (form.newPassword !== form.confirmNewPassword) {
      setError("Mật khẩu mới và xác nhận mật khẩu không khớp");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      console.log(token);
      const response = await axios.post(
        "https://server.congtien.com.vn/change-password",
        {
          currentPassword: form.currentPassword,
          newPassword: form.newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response);

      setSuccess("Đổi mật khẩu thành công");
      setTimeout(() => {
        navigate("/"); // Chuyển hướng về trang chủ sau khi đổi mật khẩu thành công
      }, 2000);
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.error);
      } else {
        setError("Đã xảy ra lỗi khi đổi mật khẩu");
      }
    }
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Đổi mật khẩu</h1>
      <Form onSubmit={handleSubmit} className="_form">
        <Form.Group className="mt-4 mb-4 _label">
          <Form.Label htmlFor="currentPassword">Mật khẩu hiện tại</Form.Label>
          <Form.Control
            type="password"
            id="currentPassword"
            name="currentPassword"
            placeholder="Nhập mật khẩu hiện tại"
            value={form.currentPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-4 _label">
          <Form.Label htmlFor="newPassword">Mật khẩu mới</Form.Label>
          <Form.Control
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder="Nhập mật khẩu mới"
            value={form.newPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-4 _label">
          <Form.Label htmlFor="confirmNewPassword">
            Xác nhận mật khẩu mới
          </Form.Label>
          <Form.Control
            type="password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            placeholder="Xác nhận lại mật khẩu mới"
            value={form.confirmNewPassword}
            onChange={handleChange}
            required
          />
        </Form.Group>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        <div className="text-center">
          <Button type="submit" variant="primary">
            Xác nhận
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default Setting;
