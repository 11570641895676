import React from "react";

const SignInForm = ({
  maNV,
  setMaNV,
  password,
  setMatKhau,
  error,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <h1 className="auth">Đăng nhập</h1>
      <span className="span_auth h4">Chào mừng đến với CVTEC E-LEARNING</span>
      <div className="social-container"></div>

      <input
        className="auth_input"
        type="text"
        placeholder="Nhập Mã NV (Nhập đủ 5 số: 000xx)"
        value={maNV}
        onChange={(e) => setMaNV(e.target.value)}
        required
      />
      <input
        className="auth_input"
        type="password"
        placeholder="Nhập Mật khẩu"
        value={password}
        onChange={(e) => setMatKhau(e.target.value)}
        required
      />
      <button className="auth_btn" type="submit">
        Sign In
      </button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default SignInForm;
