import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import axios from "axios";

const Dashboard = () => {
  const [nhanViens, setNhanViens] = useState([]);
  const [monHocs, setMonHocs] = useState([]);

  useEffect(() => {
    const fetchNhanViens = async () => {
      try {
        const response = await axios.get(
          "https://server.congtien.com.vn/nhanvien"
        );
        setNhanViens(response.data);
      } catch (error) {
        console.error("There was an error fetching the NhanViens!", error);
      }
    };

    const fetchMonhocs = async () => {
      try {
        const response = await axios.get(
          "https://server.congtien.com.vn/monhoc"
        );
        setMonHocs(response.data);
      } catch (error) {
        console.error("There was an error fetching the MonHocs!", error);
      }
    };

    fetchMonhocs();
    fetchNhanViens();
  }, []);

  return (
    <div>
      <h1 className="mt-4">Dashboard</h1>
      <Row className="mt-4">
        <Col md={3}>
          <Card className="text-white bg-primary mb-3">
            <Card.Body>
              <Card.Title>Tổng số nhân viên</Card.Title>
              <Card.Text>{nhanViens.length}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="text-white bg-primary mb-3">
            <Card.Body>
              <Card.Title>Tổng số môn học</Card.Title>
              <Card.Text>{monHocs.length}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Other cards for additional stats */}
      </Row>
    </div>
  );
};

export default Dashboard;
