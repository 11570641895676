// About.js
import React from "react";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about">
        <div class="user">
          <img className="img-user" src="https://i.imgur.com/x7LG369.jpeg" />
          <h3 class="name-user">NGUYỄN THỊ THÚY LIỂU</h3>
          <p class="title-user">Nhóm Trưởng Lean</p>
        </div>
        {/* <div class="user">
          <img className="img-user" src="https://i.imgur.com/XvtdTEc.jpeg" />
          <h3 class="name-user">Nguyễn Duy Khương</h3>
          <br />
          <p class="title-user">Phó Phòng Công Nghệ Lean</p>
        </div> */}
        <div class="user">
          <img className="img-user" src="https://i.imgur.com/BrtHi7V.jpeg" />
          <h3 class="name-user">BÙI THỊ NGỌC TUYỀN</h3>
          <p class="title-user">Trưởng Phòng Đảm Bảo Chất Lượng</p>
        </div>
        {/* <div class="user">
          <img className="img-user" src="https://i.imgur.com/QntaFbN.jpeg" />
          <h3 class="name-user">Võ Thị Thùy Trang</h3>
          <br />
          <p class="title-user">Nhân Viên Lean</p>
        </div> */}
        {/* <div class="user">
          <img className="img-user" src="https://i.imgur.com/vZZbPm4.jpeg" />
          <h3 class="name-user">Nguyễn Văn C</h3>
          <p class="title-user">Trưởng Phòng</p>
        </div> */}
      </div>
    </>
  );
};

export default About;
