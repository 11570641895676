import React, { useState } from "react";
import axios from "axios";
import "./QuestionsComponent.css";

const QuestionsComponent = ({ questions }) => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const _idNV = localStorage.getItem("_idNV");
  const MaNV = localStorage.getItem("manv");
  const idmonhoc = localStorage.getItem("idmonhoc");

  const handleAnswerChange = (questionId, answer) => {
    setAnswers({
      ...answers,
      [questionId]: answer,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.keys(answers).length !== questions.length) {
      alert("Bạn phải trả lời tất cả các câu hỏi trước khi nộp bài.");
      return;
    }

    let correctAnswers = 0;
    const ketQuaChiTiet = questions.map((question) => {
      const isCorrect = answers[question._id] === question.DapAnDung;
      if (isCorrect) correctAnswers += 1;

      return {
        IDCauHoi: question._id,
        LuaChon: answers[question._id],
        Dung: isCorrect,
      };
    });

    const totalQuestions = questions.length;
    const calculatedScore = (correctAnswers / totalQuestions) * 10;

    setScore(calculatedScore);
    setSubmitted(true);

    try {
      const ketquadaluu = await axios.post(
        "https://server.congtien.com.vn/ketqua",
        {
          _idMaNV: _idNV,
          Diem: calculatedScore,
          _idMonHoc: idmonhoc, // Assuming all questions are from the same subject
          ketQuaChiTiet,
        }
      );
      console.log(ketquadaluu);

      setShowNotification(true);
    } catch (error) {
      console.error("Lỗi khi lưu kết quả:", error);
      alert("Đã xảy ra lỗi khi lưu kết quả.");
    }

    // Reset state after submission
    setAnswers({});

    console.log("Submitted answers:", answers);
    console.log("Calculated Score:", calculatedScore);
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
    window.open("/ctdt", "_top");
  };

  return (
    <div className="questions-form">
      <h2>Danh sách câu hỏi</h2>
      {questions.map((question) => (
        <div key={question._id} className="card mb-4">
          <div className="card-body">
            <p className="question-text">{question.NDCauHoi}</p>
            <div className="mt-3">
              {["A", "B", "C", "D"].map((option) => (
                <div key={option} className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`question-${question._id}`}
                    id={`question-${question._id}-${option}`}
                    value={option}
                    onChange={() => handleAnswerChange(question._id, option)}
                    checked={answers[question._id] === option}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`question-${question._id}-${option}`}
                  >
                    {question[option]}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      <div className="input-maNV">
        <label>
          Mã nhân viên:
          <input disabled type="text" value={MaNV} className="input-field" />
          <input type="hidden" value={_idNV} className="input-field" />
        </label>
      </div>
      <button type="submit" className="submit-button" onClick={handleSubmit}>
        Nộp bài
      </button>
      {submitted && showNotification && (
        <div className="notification-overlay">
          <div className="notification-box">
            <span>
              Bài làm của bạn đã được nộp. Điểm số của bạn là: {score}/10
            </span>
            <button className="close-button" onClick={handleCloseNotification}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsComponent;
